import React from "react";
import logo from "../assets/images/PentagonePng.png";
import style from "./Css/Footer.module.css";
import { FaFacebookF, FaInstagram, FaLinkedinIn, FaEnvelope, FaPhoneAlt, FaMapMarkerAlt } from "react-icons/fa";

const Footer = () => {
    return (
        <div>
            <footer className={style.footer}>
                <div className={style.topSection}>
                    <div className={style.leftSide}>
                        <p>Get connected with us on social networks:</p>
                    </div>
                    <div className={style.rightSide}>
                        <div className={style.socialIcons}>
                            <FaFacebookF className={style.icon} />
                            <FaInstagram className={style.icon} />
                            <FaLinkedinIn className={style.icon} />
                        </div>
                    </div>
                </div>
                <div className={style.columns}>
                    <div className={style.logoColumn}>
                        <img src={logo} alt="Logo" className={style.logo} />
                    </div>
                    <div className={style.column}>
                        <h3>Product</h3>
                        <ul>
                            <li>Industries</li>
                            <li>Shape & Style</li>
                            <li>Material</li>
                        </ul>
                    </div>
                    <div className={style.column}>
                        <h3>Useful Links</h3>
                        <ul>
                            <li>Home</li>
                            <li>Get a Quote</li>
                            <li>Products</li>
                            <li>Login</li>
                        </ul>
                    </div>
                    <div className={style.column}>
                        <h3>Contact</h3>
                        <ul>
                            <li><FaEnvelope className={style.icon} /> email@example.com</li>
                            <li><FaPhoneAlt className={style.icon} /> +123 456 7890</li>
                            <li><FaMapMarkerAlt className={style.icon} /> 1234 Street, City</li>
                        </ul>
                    </div>
                </div>
                <div className={style.copyrightRow}>
                    <p>© 2023 Copyright: pentagonpackaging.com</p>
                </div>
            </footer>
        </div>
    );
};

export default Footer;
