import React, { useState, useEffect } from 'react';
import styles from './Css/Navbar1.module.css';
import logo from '../assets/images/PentagonePng.png';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

function Navbar1() {
  const navigate = useNavigate();
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [isSticky, setIsSticky] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      // Check if scroll position is greater than 100px
      if (window.scrollY > 100) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    // Add scroll event listener
    window.addEventListener('scroll', handleScroll);

    // Call handleScroll once to set initial state
    handleScroll();

    // Cleanup event listener on component unmount
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!mobileMenuOpen);
  };

  return (
    <nav className={`${styles.navbar1} ${isSticky ? styles.sticky : ''}`}>
      <div className={styles.container}>
        <div className={styles.left}>
          <img src={logo} alt="Pentagone Logo" className={styles.logo} onClick={() => navigate('/')} />
          <div className={styles.desktopLinks}>
            <a href="#home" className={styles.navLink}>Home</a>
            <a href="#industries" className={styles.navLink}>Industries</a>
            <a href="#material" className={styles.navLink}>Material</a>
            <a href="#shape-style" className={styles.navLink}>Shape & Style</a>
            <a href="#label" className={styles.navLink}>Label & Stickers</a>
          </div>
        </div>
        <div className={styles.right}>
          <Link to="/contactus" className={styles.contactLink}>Contact Us</Link>
          <div className={styles.hamburger} onClick={toggleMobileMenu}>
            &#9776;
          </div>
        </div>
      </div>

      {/* Mobile Menu */}
      {mobileMenuOpen && (
        <div className={styles.mobileMenu}>
          <a href="#home" className={styles.navLink}>Home</a>
          <a href="#industries" className={styles.navLink}>Industries</a>
          <a href="#material" className={styles.navLink}>Material</a>
          <a href="#shape-style" className={styles.navLink}>Shape & Style</a>
          <a href="#label" className={styles.navLink}>Label & Stickers</a>
          <a href="#contact" className={styles.contactLink}>Contact Us</a>
        </div>
      )}
    </nav>
  );
}

export default Navbar1;
