import React from 'react';
import styles from './Css/QuotForm.module.css';

function QuoteForm() {
  return (
    <div className={styles.formContainer}>
      <h2>Get Quote</h2>
      <form>
        
        {/* Product Information */}
        <div className={styles.formField}>
          <label>Product Information</label>
          <input type="text" name="productInfo" className={styles.inputField} placeholder="Enter product details" />
        </div>

        {/* Image Uploader */}
        <div className={styles.formField}>
          <label>Upload Image</label>
          <input type="file" name="imageUpload" className={styles.inputField} />
        </div>

        {/* 3 Input Fields and 1 Select Field */}
        <div className={`${styles.formField} ${styles.row}`}>
          <input type="text" name="field1" className={styles.inputField} placeholder="Field 1" />
          <input type="text" name="field1" className={styles.inputField} placeholder="Field 1" />
          <input type="text" name="field2" className={styles.inputField} placeholder="Field 2" />
          <select name="selectField" className={styles.selectField}>
            <option value="" disabled selected>Select Option</option>
            <option value="option1">Option 1</option>
            <option value="option2">Option 2</option>
          </select>
        </div>

        {/* 2 Select Fields and 1 Input Field */}
        <div className={`${styles.formField} ${styles.row}`}>
          <select name="selectField2" className={styles.selectField}>
            <option value="" disabled selected>Select Option</option>
            <option value="optionA">Option A</option>
            <option value="optionB">Option B</option>
          </select>
          <select name="selectField3" className={styles.selectField}>
            <option value="" disabled selected>Select Option</option>
            <option value="optionX">Option X</option>
            <option value="optionY">Option Y</option>
          </select>
          <input type="text" name="field3" className={styles.inputField} placeholder="Field 3" />
        </div>

        {/* Name, Email, Number */}
        <div className={`${styles.formField} ${styles.row}`}>
          <input type="text" name="name" className={styles.inputField} placeholder="Name" />
          <input type="email" name="email" className={styles.inputField} placeholder="Email" />
          <input type="tel" name="number" className={styles.inputField} placeholder="Phone Number" />
        </div>

        {/* Textbox */}
        <div className={styles.formField}>
          <label>Additional Information</label>
          <textarea name="additionalInfo" className={styles.textareaField} placeholder="Enter any additional information"></textarea>
        </div>

        {/* Submit Button */}
        <button type="submit" className={styles.submitButton}>
          Submit
        </button>
      </form>
    </div>
  );
}

export default QuoteForm;
