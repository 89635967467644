import React from 'react'
import HeaderSlider from '../Components/HeaderSlider'
import AllServices from '../Components/AllServices'
import Industries from '../Components/Industries'
import Howtoorder from '../Components/Howtoorder'
import Product from '../Components/Product'
import MapQuote from '../Components/MapQuote'
import Footer from '../Components/Footer'

function Home() {
  return (
    <>
        <HeaderSlider/>
        <AllServices/>
        <Industries/>
        <Howtoorder/>
        <Product/>
        <MapQuote/>
        

    </>
  )
}

export default Home