import React from 'react';
import styles from './Css/Navbar.module.css';

function Navbar() {
  return (
    <>
      <nav className={styles.navbar}>
        <div className={styles.container}>
          <a className={styles.email} href="mailto:youremail@example.com">
            youremail@example.com
          </a>
          <div className={styles.rightSide}>
            <span className={styles.phone}>123-456-7890</span>
            <a className={styles.quote} href="#get-quote">
              Get a Quote
            </a>
          </div>
        </div>
      </nav>
    </>
  );
}

export default Navbar;
